import { sendRemoteStop } from "../services/transactions/sendRemoteStop";
import {
  getAllActiveTransactionsForChargepoint,
  searchAllActiveTransactions,
} from "../services/transactions/Reporting/Reporting_service";
import { timer } from "./timer";
import { getMyTransactionsActive } from "../services/transactions/MyTransactions/MyTransactions_service";
import { logger } from "./logger";
import { StateHandler } from "../model/Utilities/Types";
import { ActiveTransaction } from "../model/Classes/Transaction";

/**
 * Utility function for remote stopping an active transaction. The function initially creates a data object
 * containing the transaction id, and sends it to the backend with the sendRemoteStop() service function. If it
 * fails log the error and set the remoteStopError state to true. If its successful, call the correct service
 * function for updating the active transactions (the code knows which to call based on the forWhat parameter).
 * If this fails, log the error and set the remoteStopSuccessButRefreshActive state to true. Otherwise, update
 * the allActiveTransactions state with the new data and set the remoteStopSuccess state to true.
 *
 * In short, there's three cases:
 * 1) Fails when remote stopping: log the error and set remoteStopError state to true
 * 2) Remote stopping succesfull, but refreshing active transactions fail: log the error and set the remoteStopSuccessButRefreshActive state to true
 * 3) Both are successful: update the activeTransactions state and set the remoteStopSuccess state to true
 * @param {*} transactionId id of the transaction to stop
 * @param {*} cpId id of the chargepoint the transactions takes place for
 * @param {*} areaId id of the area where the chargepoint recides
 * @param {*} forWhat string indicating which component called the function
 * @param {*} setRemoteStopSuccess state handler for updating the remoteStopSuccess state (shows an alert)
 * @param {*} setRemoteStopError state handler for updating the remoteStopError state (shows an alert)
 * @param {*} setRemoteStopSuccessButRefreshActive state handler for updating the remoteStopSuccessButRefreshActive state (shows an alert)
 * @param {*} setAllActiveTransactions state handler for updating the allActiveTransactions state
 */
export const remoteStop = async (
  transactionId: number,
  forWhat: string,
  setRemoteStopSuccess: StateHandler<boolean>,
  setRemoteStopError: StateHandler<boolean>,
  setRemoteStopSuccessButRefreshActive: StateHandler<boolean>,
  setAllActiveTransactions: StateHandler<ActiveTransaction[]>,
  areaId?: number,
  cpId?: string,
  waitforstop?: boolean
) => {
  //Create the data object to be sent to the backend
  const data = {
    transaction_id: transactionId,
    charge_point_id: cpId,
    waitforstop: waitforstop || true,
  };

  //Call the service function sendRemoteStop to stop the transaction
  const res = await sendRemoteStop(data);
  //Check if the remote stop was successful
  if (res.success) {
    //It was, now try to refresh the active transactions
    const activeRes = await remoteStopHelper(forWhat, areaId, cpId); //Call helper function

    //Everything's successful (Case 3), now update the allActiveTransactions state with the new data and set the
    //remoteStopSuccess alert to true
    if (activeRes.success) {
      setAllActiveTransactions(activeRes.data);
      timer(setRemoteStopSuccess);
    } else {
      //Some error occured when refreshing the active transactions (Case 2)
      logger(activeRes.data);
      //Set the remoteStopSuccessButRefreshActive state to true
      timer(setRemoteStopSuccessButRefreshActive);
    }
  } else {
    //Remote stopping failed (Case 1)
    logger(res.error);

    //Set the remoteStopError state to true
    timer(setRemoteStopError);
  }

  /**
   * Helper function for the remoteStop util function. Checks which component called the util function,
   * and calls the corresponding service function. If successfully, sets the first parameter to contain
   * the data, or throws an error otherwise
   * @param {*} forWhat string indicating which component called the function
   * @param {*} areaId id of the area where the chargepoint recides
   * @param {*} cpId id of the chargepoint the transactions takes place for
   */
  async function remoteStopHelper(forWhat: string, areaId?: number, cpId?: string) {
    //The LandingPage or MyTransactions components called the function
    if (forWhat === "landingPage" || forWhat === "myTransactions") return await getMyTransactionsActive();

    //The Chargepoint component called the function
    if (forWhat === "chargepoint")
      return await getAllActiveTransactionsForChargepoint({
        areaId: areaId,
        cpId: cpId,
      });

    return await searchAllActiveTransactions({ area_id: areaId });
  }
};
