import { Nullable } from "../Utilities/Types";
import { AccessType } from "./Area";
import { ActiveTransaction, CompletedTransaction } from "./Transaction";

export abstract class ChargepointClass {
  // The interfaces used are bloated and need a full rewrite

  static formatNickname = (data: CpBase | ActiveTransaction | CompletedTransaction): string => {
    if (data.nickname) {
      return data.nickname;
    } else if (data.charge_point_id) {
      return data.charge_point_id;
    } else {
      return "";
    }
  };

  static formatNumber = (data: CpBase | ActiveTransaction | CompletedTransaction): string => {
    return `${data.cp_prefix}-${data.cp_number}`;
  };

  static toString = (data: CpBase | ActiveTransaction | CompletedTransaction): string => {
    const nickname = ChargepointClass.formatNickname(data);
    const number = ChargepointClass.formatNumber(data);

    if (nickname.length) {
      return `${nickname} / ${number}`;
    } else {
      return number;
    }
  };

  static toStringWithConnector = (data: CpBase | ActiveTransaction | CompletedTransaction): string => {
    const nickname = ChargepointClass.formatNickname(data);
    let cpInfo: string = ChargepointClass.formatNumber(data);

    if (data.connector_id!) {
      cpInfo = `${cpInfo}-${data.connector_id}`;
    }
    if (nickname.length) {
      return `${nickname} / ${cpInfo}`;
    } else {
      return cpInfo;
    }
  };

  static toStringWithId = (data: CpBase | ActiveTransaction | CompletedTransaction): string => {
    const cpString = ChargepointClass.toString(data);

    // If we have a nickname, ID will not be in cpString, so add it
    if (data.nickname && data.charge_point_id) {
      return `${cpString} (${data.charge_point_id})`;
    } else {
      return `${cpString} (${data.charge_point_id})`;
    }
  };
}

export type ChargepointMap = Map<string, TransactionCp>;

export default interface CpFull extends CpBase {
  address: Nullable<string>;
  latitude: number;
  longitude: number;
  last_heartbeat?: Date;
  phase?: number;
  connected?: number;
  health?: number;
  chargepoint_profile?: number;
  area_name?: string;
  charge_point_vendor?: string;
  charge_point_model?: string;
  firmware_version?: string;
  number_of_connectors?: number;
  connector_limit: number;
}

export interface TransactionCp extends CpBase, SpotPrice {
  address: Nullable<string>;
  latitude: number;
  longitude: number;
  price: number;
  number_of_connectors?: number;
  access_type: number;
  business_Id: string;
  ownerName: string;
  invoicing_method: number;
  free_charging?: number;
}

export interface CpBase {
  charge_point_id?: string;
  area_id?: number;
  cp_number?: number;
  cp_prefix?: string;
  connector_id?: number;
  nickname?: Nullable<string>;
  access_type?: AccessType;
  public_price?: Nullable<number>;
  disabled?: number;
  public_start_price?: Nullable<number>;
  price?: number;
  currency?: string;
}

export interface SpotPrice {
  pricing_type?: number;
  bidding_area?: Nullable<string>;
  margin?: number;
}
