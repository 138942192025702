import React, { useState, useEffect } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";
import {
  AreaView,
  Burger,
  Menu,
  Login,
  Register,
  LandingPage,
  MyTransactions,
  ResetPassword,
  MyMonthlyTransactions,
  Reporting,
  MyYearlyTransactions,
  SingleAreaView,
  UserSettings,
  ChargepointView,
  ConfirmEmail,
  StartNewTransaction,
  AdminPage,
  TermsOfService,
  PaymentToS,
  PaymentServiceProvider,
  Help,
  AddCardFormSuccess,
  AddCardFormFail,
} from "./components/indexComponents";
import { useOnClickOutside } from "./hooks";
import { checkToken } from "./services/auth/checkToken";
import { logger } from "./utils/logger";
import User from "./model/Classes/User";
import { Nullable } from "./model/Utilities/Types";
import { LightTheme } from "./theme";
import { LangDropdown } from "./components/public/LangDropdown/LangDropdown";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

/**
 * Main app component that is responsible for which component and it's subcomponents to view.
 * Also responsible for authenticating the user with the backend on each rerender (see the
 * useEffect() function below).
 *
 * @returns the page to view
 */
function App() {
  const [open, setOpen] = useState(false); //state for the menu
  const [user, setUser] = useState<Nullable<User>>(null); //state for the user object
  const [registrationSuccessAlert, setRegistrationSuccessAlert] = useState(false); //state for the alert notifying if registration was successful
  const [logoutSuccessAlert, setLogoutSuccessAlert] = useState(false); //state for the alert notifying if logout was successful
  const [resetPasswordSuccessAlert, setResetPasswordSuccessAlert] = useState(false); //state for the alert notifying if resetting a password was successful
  const [confirmEmailSuccess, setConfirmEmailSuccess] = useState(false); //state for the alert notifying if confirming an email was successful
  const [confirmEmailFail, setConfirmEmailFail] = useState(false); //state for the alert notifying if confirming an email failed
  const menuId = "main-menu";

  const node = React.createRef<HTMLDivElement>();
  useOnClickOutside(node, () => setOpen(false));

  //history object that is used throughout the application
  const history = useHistory();
  const setUserCredentials = (user) => {
    setUser(user);
  };
  /** 
  // Service worker useEffect to check for any changes when app loads
  useEffect(() => {
    navigator.serviceWorker.addEventListener('message', (event) => {
      if (event.data && event.data.type === 'SHOW_UPDATE_NOTIFICATION') {
        const confirmation = window.confirm(event.data.message);

        if (confirmation && navigator.serviceWorker.controller) {
          navigator.serviceWorker.controller.postMessage({
            type: 'SKIP_WAITING',
          });
        }
      }
    });
  }, []);
  */
  /**
   * On each rerender, check if the user passes the authentication check on the backend.
   * If it's successful, set the user data from the response to the state user. If it does not
   * pass the authentication check from the backend (either the tokens are invalid or the
   * user has not logged in), redirect to the login page with a state containing the path
   * the user tried accessing.
   */
  useEffect(() => {
    const check = async () => {
      const res = await checkToken();
      if (res.success) {
        //Successful, set the user state from the data received
        const data = res.data;
        setUser(data);
      } else {
        //If the authentication failed, redirect to the login page with a state containing the path
        //the user tried accessing
        history.push({
          pathname: "/login",
          state: { from: history.location },
        });
        logger(res.data);
      }
    };

    /*if(!history.location.pathname.startsWith("/startNewTransaction"))*/

    //If the user tries to access either the, then for the following pages:
    //1. Reset password page
    //2. Confirm email page
    //3. Terms of service page
    //Do not check if the user is logged in since it should accessible to a
    //user who is not logged in
    if (
      !history.location.pathname.startsWith("/resetPassword") &&
      !history.location.pathname.startsWith("/confirmEmail") &&
      !history.location.pathname.startsWith("/terms-of-service") &&
      !history.location.pathname.startsWith("/licensing-agreement") &&
      !history.location.pathname.startsWith("/register")
    )
      check();
  }, [history]);

  // If the user has received an email that invoicing failed, there will be
  // a link to the payment methods tab in that mail. Here we use the same history
  // state as on the landing page to show the right tab.
  if (history.location.pathname.startsWith("/settings/payment-methods")) {
    history.push({
      pathname: "/settings",
      state: { fromLandingPageShowCards: true },
    });
  }

  /**
   * The entire return value is wrapped in a a theme provider, allowing the theme to be accessed from any
   * component without passing it as a prop. The components wrapped inside the theme provider are as following:
   * GlobalStyles:
   *  - styled component that contains all the global styles for the entire application
   * Burger:
   *  - component responsible for rendering the burger menu button (and its animation) at the top left of the screen
   * Menu:
   *  - component responsible handling the functionality for the menu as well as rendering it
   * Switch and Route:
   *  - Components from the react-router-dom that check the path the user tries to access, and returns the corresponding
   *    component for that path. If the path is unknown, a 404 error message is displayed to the user
   */
  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <ThemeProvider theme={new LightTheme()}>
      <>
        <GlobalStyles />
        <div>
          <div>
            <LangDropdown i18n={i18n} currentLang={i18n.language} />
            <ToastContainer draggable />
          </div>
          <div ref={node} id="hamburger-icon">
            <Burger open={open} setOpen={setOpen} user={user} aria-controls={menuId} />
            <Menu
              open={open}
              setOpen={setOpen}
              user={user}
              setUser={setUser}
              id={menuId}
              setLogoutSuccessAlert={setLogoutSuccessAlert}
              history={history}
            />
          </div>
          <Switch>
            <Route exact path="/area/:id/chargepoint/:charge_point_id">
              {user ? (
                user.user_level > 0 ? (
                  <ChargepointView user={user} history={history} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </Route>
            <Route exact path="/area/:id">
              {user ? (
                user.user_level > 0 ? (
                  <SingleAreaView user={user} history={history} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </Route>
            <Route exact path="/startNewTransaction/:charge_point_id">
              {user ? <StartNewTransaction user={user} history={history} /> : <></>}
            </Route>
            <Route exact path="/startNewTransaction">
              <StartNewTransaction user={user} history={history} />
            </Route>
            <Route exact path="/reporting">
              {user ? (
                user.user_level > 0 ? (
                  <Reporting user={user} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </Route>
            <Route exact path="/myTransactions/:year/:month">
              {user ? <MyMonthlyTransactions user={user} history={history} /> : <></>}
            </Route>
            <Route exact path="/myTransactions/:year">
              {user ? <MyYearlyTransactions user={user} history={history} /> : <></>}
            </Route>
            <Route exact path="/myTransactions">
              {user ? <MyTransactions user={user} /> : <></>}
            </Route>
            <Route exact path="/area">
              {user ? (
                user.user_level > 0 ? (
                  <AreaView user={user} history={history} />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <></>
              )}
            </Route>
            <Route path="/paytrail/addCardForm/success">
              <AddCardFormSuccess history={history} />
            </Route>
            <Route exact path="/paytrail/addCardForm/failed">
              <AddCardFormFail history={history} />
            </Route>
            <Route exact path="/login">
              <Login
                setUser={setUserCredentials}
                user={user}
                registrationSuccessAlert={registrationSuccessAlert}
                logoutSuccessAlert={logoutSuccessAlert}
                resetPasswordSuccessAlert={resetPasswordSuccessAlert}
                history={history}
                confirmEmailSuccess={confirmEmailSuccess}
                confirmEmailFail={confirmEmailFail}
              />
            </Route>
            <Route exact path="/register">
              <Register setRegistrationSuccessAlert={setRegistrationSuccessAlert} history={history} user={user} />
            </Route>
            <Route exact path="/terms-of-service">
              <TermsOfService />
            </Route>
            <Route exact path="/payment-terms-of-service">
              <PaymentToS />
            </Route>
            <Route exact path="/payment-service-provider">
              <PaymentServiceProvider />
            </Route>
            <Route exact path="/settings">
              {user ? <UserSettings user={user} setUser={setUser} history={history} /> : <></>}
            </Route>
            <Route exact path="/help">
              {user ? <Help user={user} /> : <></>}
            </Route>
            <Route exact path="/resetPassword/:token">
              <ResetPassword history={history} setResetPasswordSuccessAlert={setResetPasswordSuccessAlert} />
            </Route>
            <Route exact path="/confirmEmail/:token">
              <ConfirmEmail
                history={history}
                setConfirmEmailSuccess={setConfirmEmailSuccess}
                setConfirmEmailFail={setConfirmEmailFail}
              />
            </Route>
            <Route exact path="/admin">
              {user ? (
                user.user_level === 2 ? (
                  <AdminPage />
                ) : (
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                )
              ) : (
                <>
                  <div className="flex top-level-component">
                    <h2 className="align-self-center text-align-center width-100">{t("global.view.noAccess")}</h2>
                  </div>
                </>
              )}
            </Route>

            {/** if there is no active transaction then the page is routhed to star new transaction page. */}
            <Route exact path="/">
              {/**    {user && activeTransaction.length > 0 ? (
                <LandingPage user={user} history={history} />
              ) : (
                <StartNewTransaction user={user} history={history} />
              )}
*/}
              {user && <LandingPage user={user} history={history} />}
            </Route>
            {/**   <Route exact path="/Home">
              
            </Route>
            */}
            {
              //The user tried to access an unknown path: display a 404 error message
            }
            <Route>
              <div className="flex top-level-component">
                <h2 className="align-self-center text-align-center width-100">Error 404: Page not found</h2>
              </div>
            </Route>
          </Switch>
        </div>
      </>
    </ThemeProvider>
  );
}

export default App;
