import axios from "axios";
import StructuredOcppResponse from "../../model/Classes/StructuredOcppResponse";
import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const tokenURL = "/api/auth/token";
const recentCpIdURL = "/api/user/chargepoints/recent/id";
const recentCpURL = "/api/user/chargepoints/recent";

export const startNewTransaction = async (data) => {
  const baseURL = "/api/transactions/remoteStart";
  try {
    const response = await axios.post(`${baseURL}`, data, {
      withCredentials: true,
    });
    return StructuredOcppResponse.success(response.data.result, response.data.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}`, data, {
        withCredentials: true,
      });
      return StructuredOcppResponse.success(response.data.result, response.data.data);
    } catch (e: any) {
      return StructuredOcppResponse.error(e.message);
    }
  }
};

export const createOptimizationSchedule = async (data) => {
  const baseURL = "/api/transactions/createOptimizationSchedule";
  try {
    const response = await axios.post(`${baseURL}`, data, {
      withCredentials: true,
    });
    return StructuredOcppResponse.success(response.data.result, response.data.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}`, data, {
        withCredentials: true,
      });
      return StructuredOcppResponse.success(response.data.result, response.data.data);
    } catch (e: any) {
      return StructuredOcppResponse.error(e.message);
    }
  }
};

//service to get recent chargepoints from backend
export const getRecentChargepointIds = async () => {
  try {
    const response = await axios.get(`${recentCpIdURL}`, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${recentCpIdURL}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};

//service to get recent chargepoints from backend
export const getRecentChargepoints = async () => {
  try {
    const response = await axios.get(`${recentCpURL}`, {
      withCredentials: true,
    });
    return new StructuredResponse(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(`${recentCpURL}`, {
        withCredentials: true,
      });
      return new StructuredResponse(true, response.data);
    } catch (e: any) {
      return new StructuredResponse(false, e);
    }
  }
};
