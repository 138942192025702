import styled from "styled-components";
import classNames from "classnames";
import { LightTheme } from "../../../theme";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(utc);
dayjs.extend(timezone);

export const TransactionScheduleBar = ({ schedule }: { schedule: object }) => {
  // Structure is { "[ISO-datetime-string]" : boolean, ... }
  // Order by date strings, parse dates and store in ordered Map
  const scheduleMap = Object.keys(schedule)
    .sort()
    .reduce((map, dateString) => {
      map.set(dayjs.utc(dateString).tz("Europe/Helsinki"), schedule[dateString]);
      return map;
    }, new Map<dayjs.Dayjs, boolean>());

  return (
    <StyledTransactionScheduleBar>
      {[...scheduleMap.keys()].map((dateObj, i) => (
        <div key={i} className={classNames({ active: !!scheduleMap.get(dateObj) })}>
          {dateObj.format("HH")}
        </div>
      ))}
    </StyledTransactionScheduleBar>
  );
};

const StyledTransactionScheduleBar = styled.div<{
  theme: LightTheme;
}>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20px, 1fr));
  grid-gap: 1px;

  div {
    background: #ccc;
    display: block;
    white-space: nowrap;
    font-size: 80%;
    text-align: center;
    border-radius: 3px;

    &.active {
      background: ${({ theme }) => theme.mediumGreen};
      color: #fff;
    }
  }
`;
