import { useState, useEffect } from "react";
import unlockIcon from "../../../resources/unlock_connector.svg";
import Tooltip from "../../GeneralComponents/Tooltip";
import Button from "react-bootstrap/Button";
import CpFull from "../../../model/Classes/Chargepoint";
import { timer } from "../../../utils/timer";
import { logger } from "../../../utils/logger";
import unlock from "../../../services/chargepoint/unlock";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { Alert, Modal, ModalBody, ModalFooter, ModalTitle } from "react-bootstrap";

declare interface UnlockConnectorModalProps {
  chargepoint: CpFull;
  chargePointId?: string;
  executionTimeExceeded: boolean;
  status?: number;
  refreshStatusTable: () => Promise<void>;
  refresh: () => Promise<void>;
}

const UnlockConnectorModal = ({
  chargepoint,
  chargePointId,
  executionTimeExceeded,
  status,
  refreshStatusTable,
  refresh,
}: UnlockConnectorModalProps) => {
  const [toggle, setToggle] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedConnector, setSelectedConnector] = useState<number | null>(null);

  //use effect for handeling the preselect of unlockconnector and passing the value as 1 when there is only one connector otherwise sets it to null and updates accordingly.
  useEffect(() => {
    if (chargepoint.number_of_connectors === 1) {
      setSelectedConnector(1); // Pre-select the only available connector
    } else {
      setSelectedConnector(null); // Reset selection if there are multiple connectors
    }
  }, [chargepoint.number_of_connectors]);
  const handleClose = () => {
    setToggle(false);
    setShow(false);
  };

  const handleShow = async () => {
    await refresh();
    await refreshStatusTable();
    setToggle(true);
  };
  useEffect(() => {
    function get() {
      if (executionTimeExceeded) {
        timer(setErrorAlert);
        return;
      }
      if (status! >= 1) {
        setShow(true);
      } else {
        timer(setFailAlert);
        setToggle(false);
      }
    }
    if (toggle) get();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggle]);

  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [warningAlert, setWarningAlert] = useState(false);
  const [failAlert, setFailAlert] = useState(false);
  const [unlocking, setUnlocking] = useState(false);
  //const [alertMessage, setAlertMessage] = useState('');

  const handleUnlockConnector = async () => {
    const connectorData = {
      charge_point_id: chargePointId,
      connector_id: selectedConnector,
    };
    setUnlocking(true);
    const unlockRes = await unlock(connectorData);

    setUnlocking(false);

    handleClose();
    //setAlertMessage(unlockRes.result);
    if (!chargepoint.connected) {
      timer(setErrorAlert);
    } else if (!unlockRes.success) {
      logger(unlockRes.error);
      timer(setWarningAlert);
    } else {
      if (unlockRes.success === true && unlockRes.result === "Unlocked") {
        timer(setSuccessAlert);
      } else if (unlockRes.success === true && unlockRes.result !== "Unlocked") {
        timer(setFailAlert);
      } else {
        timer(setWarningAlert);
      }
    }
  };

  const { t } = useTranslation("common", { i18n: i18n });
  return (
    <>
      <div>
        <Tooltip
          text={t("components.chargepoint.static.unlockConnector.unlockConnectorBtn")}
          placement="bottom"
          variant="light"
        >
          <Button
            variant="none"
            className="button"
            onClick={handleShow}
            onMouseDown={(e) => e.preventDefault()}
            style={{
              width: "25px",
              height: "25px",
              padding: "0",
              display: "block",
              //margin: '0 auto ',
              //border: ' 1px solid red',
            }}
          >
            <img
              src={unlockIcon}
              alt="Unlock Connector"
              style={{
                height: "24px",
                width: "24px",
              }}
            />
          </Button>
        </Tooltip>
      </div>
      <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
        <ModalHeader>
          <ModalTitle>{t("components.chargepoint.static.unlockConnector.modal.unlockOptHeader")}</ModalTitle>
          <Button
            type="button"
            className="close"
            aria-label="Close"
            variant="outline-ligth"
            onClick={handleClose}
            disabled={unlocking}
          >
            <span aria-hidden="true">&times;</span>
          </Button>
        </ModalHeader>
        <ModalBody>
          {chargepoint.number_of_connectors && chargepoint.number_of_connectors > 0
            ? Array.from({ length: chargepoint.number_of_connectors }, (val, index) => index + 1).map(
                (connectorNumber) => (
                  <div key={connectorNumber}>
                    <input
                      type="checkbox"
                      name="connector"
                      value={connectorNumber}
                      onChange={() => setSelectedConnector(connectorNumber)}
                      checked={selectedConnector === connectorNumber}
                      style={{ margin: "0 0 15px 0" }}
                    />
                    <label style={{ marginLeft: "5px" }}>
                      {t("components.chargepoint.static.unlockConnector.modal.connectorNum")}
                      {connectorNumber}
                    </label>
                  </div>
                )
              )
            : null}
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleUnlockConnector} variant="primary" disabled={unlocking}>
            {t("components.chargepoint.static.unlockConnector.modal.unlockBtn")}
          </Button>
          <Button onClick={handleClose} variant="secondary" disabled={unlocking}>
            {t("components.chargepoint.static.unlockConnector.modal.cancelBtn")}
          </Button>
        </ModalFooter>
      </Modal>

      {successAlert ? (
        <Alert
          variant="success"
          key="success"
          style={{
            margin: "10px auto 10px auto",
            width: "80% !important",
            textAlign: "center",
            left: "-50%",
          }}
        >
          {t("components.chargepoint.static.unlockConnector.unlockAccepted")}
        </Alert>
      ) : null}
      {warningAlert ? (
        <Alert
          variant="warning"
          key="warning"
          style={{
            margin: "10px auto 10px auto",
            width: "80% !important",
            textAlign: "center",
            left: "-50%",
          }}
        >
          {t("components.chargepoint.static.unlockConnector.unlockWarning")}
        </Alert>
      ) : null}
      {errorAlert ? (
        <Alert
          variant="danger"
          key="error"
          style={{
            margin: "10px auto 10px auto",
            width: "80% !important",
            textAlign: "center",
            left: "-50%",
          }}
        >
          {t("components.chargepoint.static.unlockConnector.connectorNotConnected")}
        </Alert>
      ) : null}
      {failAlert ? (
        <Alert
          variant="danger"
          key="error"
          style={{
            margin: "10px auto 10px auto",
            width: "80% !important",
            textAlign: "center",
            left: "-50%",
          }}
        >
          {t("components.chargepoint.static.unlockConnector.unlockFailed")}
        </Alert>
      ) : null}
    </>
  );
};

export default UnlockConnectorModal;
