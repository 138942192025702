export default class StructuredOcppResponse<T> {
  private readonly _success: boolean;
  private readonly _result: T;
  private readonly _data: any | null;
  private readonly _id: string | null;
  private readonly _error: string | null;

  constructor(success: boolean, result: T, data: any | null, id: string | null, error: string | null) {
    this._success = success;
    this._result = result;
    this._data = data;
    this._id = id;
    this._error = error;
  }

  public static success<T>(result: T, data: any | null = null, id: string | null = null): StructuredOcppResponse<T> {
    return new StructuredOcppResponse<T>(true, result, data, id, null);
  }

  public static error(error: string | null = null): StructuredOcppResponse<any> {
    return new StructuredOcppResponse<any>(false, null, null, null, error);
  }

  public get success(): boolean {
    return this._success;
  }

  public get result(): T {
    return this._result;
  }

  public get data(): any | null {
    return this._data;
  }

  public get id(): string | null {
    return this._id;
  }

  public get error(): string | null {
    return this._error;
  }

  // For backwards compatibility
  public get message(): string | null {
    return this._error;
  }
}
