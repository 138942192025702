import axios from "axios";
import StructuredResponse from "../../model/Classes/StructuredResponse";
axios.defaults.withCredentials = true;

const tokenURL = "/api/auth/token";

export const getIsLoadBalanced = async (area_id: number) => {
  const apiAddress = `/api/area/${area_id}/isLoadBalanced`;

  try {
    const response = await axios.get(apiAddress);
    return new StructuredResponse<boolean>(true, response.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.get(apiAddress);
      return new StructuredResponse<boolean>(true, response.data);
    } catch (e: any) {
      return new StructuredResponse<boolean>(false, e);
    }
  }
};
