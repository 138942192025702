import axios from "axios";
import StructuredOcppResponse from "../../model/Classes/StructuredOcppResponse";
axios.defaults.withCredentials = true;

const baseURL = "/api/transactions/remoteStop";
const tokenURL = "/api/auth/token";

/**
 * Service function responsible for sending a remote stop request for a transaction.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing transaction id and area id
 * @returns If successful: [true, response.data]. Otherwise: [false, error]
 */
export const sendRemoteStop = async (data) => {
  try {
    const response = await axios.post(`${baseURL}`, data, {
      withCredentials: true,
    });
    return StructuredOcppResponse.success(response.data.result, response.data.data);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}`, data, {
        withCredentials: true,
      });
      return StructuredOcppResponse.success(response.data.result, response.data.data);
    } catch (e: any) {
      return StructuredOcppResponse.error(e.message);
    }
  }
};
