import React from "react";
import { Image } from "react-bootstrap";
import styled from "styled-components";

import ChargePoint, { ChargepointClass } from "../../../model/Classes/Chargepoint";
import { QRCode } from "react-qrcode-logo";
import watteryLogo from "../../../resources/WatteryLogoGreen.svg";

declare interface ChargepointQRProps {
  chargepoint: ChargePoint;
  width: number;
}

export const ChargepointQR = ({ chargepoint, width }: ChargepointQRProps) => {
  const url = `${window.location.origin}/startNewTransaction/${chargepoint.charge_point_id}`;

  return (
    <StyledChargepointQR>
      <Image src={watteryLogo} width={width} />
      <div>
        <QRCode value={url} size={600} ecLevel="M" quietZone={0} fgColor="#000000" qrStyle="squares" />
      </div>
      <div>{ChargepointClass.formatNickname(chargepoint)}</div>
      <div>{window.location.host}</div>
    </StyledChargepointQR>
  );
};

export const PrintableChargepointQR = React.forwardRef<HTMLDivElement, ChargepointQRProps>(
  ({ chargepoint, width }: ChargepointQRProps, ref) => {
    return (
      <div ref={ref}>
        <ChargepointQR chargepoint={chargepoint} width={width} />
      </div>
    );
  }
);

const StyledChargepointQR = styled.div`
  margin-bottom: 2rem;
`;
