import axios from "axios";
import StructuredOcppResponse from "../../model/Classes/StructuredOcppResponse";
axios.defaults.withCredentials = true;

const baseURL = "/api/transactions/reset";
const tokenURL = "/api/auth/token";

const reboot = async (rebootData) => {
  try {
    const response = await axios.post(`${baseURL}`, rebootData, {
      withCredentials: true,
    });
    return StructuredOcppResponse.success(response.data.result);
  } catch (e) {
    try {
      await axios.post(tokenURL, { withCredentials: true });
      const response = await axios.post(`${baseURL}`, rebootData, {
        withCredentials: true,
      });
      return StructuredOcppResponse.success(response.data.result);
    } catch (e: any) {
      return StructuredOcppResponse.error(e.message);
    }
  }
};

export default reboot;
